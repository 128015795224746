import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";

import prism from "./one-light";
SyntaxHighlighter.registerLanguage("jsx", jsx);

const Code = ({ children }) => {
  const props = {
    language: "javascript",
    customStyle: {
      backgroundColor: "#fafafa",
      fontSize: "0.9em",
    },
    style: prism, // change this depending what you imported
  };

  return (
    <div className="code">
      <SyntaxHighlighter {...props}>{children}</SyntaxHighlighter>
    </div>
  );
};

export default Code;
