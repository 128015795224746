import "./timeTag.scss";

function TimeTag({ postTime, postTags }) {
  return (
    <>
      <div className="wrappers date-wrapper">{postTime}</div>
      <div className="wrappers tags-wrapper">
        {postTags.map((tag, index) => (
          <span key={`tag-${index}`} className="tag">
            {tag}
          </span>
        ))}
      </div>
    </>
  );
}

export default TimeTag;
