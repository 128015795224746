import { goodbyeWordPressExcerpt } from "./excerptsData";

export const articlesData = [
  // {
  //   id: 0,
  //   title: "This is a markdown test file",
  //   time: "July 31, 2024",
  //   excerpt: "Excerpt to be displayed in POSTS page", //MD format
  //   tags: ["test", "react"], // tags for this article
  //   slug: "test-me", // what is going to be part of the URL
  //   src: "./posts/test.md", // markdown file with the article contents
  // },
  {
    id: 1,
    title: "Goodbye WordPress",
    time: "July 15th, 2024",
    excerpt: goodbyeWordPressExcerpt,
    tags: ["React", "WordPress", "MarkDown" ], // tags for this article
    slug: "goodbye-wordpress", // what is going to be part of the URL
    src: "./posts/goodbye-wordpress.md", // markdown file with the article contents
  },
];
