import { Outlet } from "react-router-dom";
import About from "../About";
import "./layout.scss";

function Layout() {
  return (
    <div className="layout">
      {/* <div className="left">
        <About />
      </div>
      <div className="right">
        <div className="content column">
          <Outlet />
        </div>
      </div> */}

      <div className="main-grid">
        <div className="left">
          <About />
        </div>
        <div className="right">
          <div className="content column">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
