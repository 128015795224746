import Markdown from "markdown-to-jsx";
import Code from "../Code";

function ArticleContent({content}) {
  return (
    <div className="article-wrapper">
      <article>
        <main>
          <Markdown
            options={{
              overrides: {
                code: {
                  component: Code,
                },
              },
            }}
          >
            {content}
          </Markdown>
        </main>
      </article>
    </div>
  );
}

export default ArticleContent;
