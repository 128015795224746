import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div style={{ maxWidth: "350px" }}>
      <h1 style={{ fontSize: "4em", marginTop: "42px", fontWeight: "400" }}>
        404
      </h1>
      <h2>Our sincere apologies</h2>
      <p>
        We made every effort to locate the page, but unfortunately, we were
        unable to find it.
        <br />
        Thank you for your understanding
      </p>
      <Link to="/" className="button">
        Return to home page
      </Link>
    </div>
  );
}

export default NotFound;
