import { Helmet } from "react-helmet";

function HelmetComponent({ article }) {
  const tags = article.tags.toString();

  return (
    <Helmet>
      <title>{article?.title}</title>
      <meta name="author" content="picssel.com - Francisco Diaz" />
      <meta name="description" content={article?.excerpt} />
      <meta name="keywords" content={tags} />
      <meta property="og:title" content={article?.title} />
      <meta property="og:description" content={`og:${article?.excerpt}"`} />
      <meta property="og:image" content="" />
      <meta property="og:url" content={`//picssel.com/${article?.slug}`} />
      <meta name="twitter:title" content={article?.title} />
      <meta name="twitter:description" content={`og:${article?.excerpt}"`} />
      <meta name="twitter:image" content="" />
      <meta name="twitter:card" content="" />
    </Helmet>
  );
}

export default HelmetComponent;
