import { Link } from "react-router-dom";
import ArticleContent from "../ArticleContent/";
import TimeTag from "../TimeTag";
import { articlesData } from "./data";
import { Helmet } from "react-helmet";

import "./post.scss";

function Posts() {
  return articlesData.map((article) => {
    return (
      <>
        <Helmet>
          <title>picssel.com</title>
          <meta name="author" content="picssel.com - Francisco Diaz" />
          <meta
            name="description"
            content="This is the development studio of Francisco Diaz, a seasoned web developer living in Vancouver, Canada."
          />
          <meta
            name="keywords"
            content=" blog, picssel, javascript, react, jQuery, HTML5, CSS3, node, nodejs"
          />
          <meta property="og:url" content="//picssel.com" />
        </Helmet>
        <div className="post" key={article.id}>
          <div className="post-wrapper">
            <h2 className="title">
              <Link className="post-link" to={article.slug}>
                {article.title}
              </Link>
            </h2>
            <ArticleContent content={article.excerpt} />
          </div>
          <div className="read-more">
            <Link to={article.slug}>read more</Link>
          </div>
          <TimeTag postTime={article.time} postTags={article.tags} />
        </div>
      </>
    );
  });
}

export default Posts;
