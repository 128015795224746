import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Posts from "./components/Posts";
import Article from "./components/Article";
// pages
// import PageOne from "./pages/PageOne";
// import PageTwo from "./pages/PageTwo";
// CSS
import "./App.css";
import NotFound from "./components/NotFound";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Posts />} />
            {/* <Route path="uno" element={<PageOne />} /> */}
            {/* <Route path="dos" element={<PageTwo />} /> */}
            <Route path="404" element={<NotFound />} />
            <Route path=":slug" element={<Article />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
