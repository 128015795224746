export const goodbyeWordPressExcerpt = `
After many years of using WordPress, I've decided to transition to a React/Markdown-based blog. While WordPress offers robust features and easy-to-use tools for bloggers, it no longer fits my needs.

WordPress has numerous advantages, such as:

- **Ease of Use**: It's straightforward to set up and start blogging without deep technical knowledge.
- **Extensive Plugin and Theme Ecosystem**: Thousands of plugins and themes to customize your site.

However, I've encountered several issues that made WordPress less ideal for me:
`;
