import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { articlesData } from "src/components/Posts/data";
import ArticleContent from "src/components/ArticleContent/";
import TimeTag from "../TimeTag";
import HelmetComponent from "../Helmet";

import "./article.scss";

const Article = () => {
  const [articleContent, setArticleContent] = useState("");
  const navigate = useNavigate();
  const { slug } = useParams();
  const article = articlesData.filter((item) => item.slug === slug)[0];

  useEffect(() => {
    const fetchPost = async () => {
      await fetch(article?.src)
        .then((response) => response.text())
        .then((text) => setArticleContent(text.toString()))
        .catch((err) => console.error(err));
    };

    if (article) {
      fetchPost();
    } else {
      navigate("/404");
    }
    return () => {};
  }, [article, navigate]);

  return (
    !!articleContent?.length && (
      <>
        <HelmetComponent article={article} />
        <ArticleContent content={articleContent} />
        <TimeTag postTime={article.time} postTags={article.tags} />
      </>
    )
  );
};

export default Article;
