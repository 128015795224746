import { Link } from "react-router-dom";

import "./about.scss";
import { useState } from "react";

function About() {
  const [menuActive, setMenuActive] = useState(false)

  const handleMenu = () => setMenuActive(!menuActive)

  return (
    <div className="about column">
      <div className="head">
        <Link to="/">
          <img src="./picssel_logo224x60.png" alt="picssel logo" />
        </Link>
        <div className="menu">
            <img className="menu-icon" src={`./assets/${menuActive ? 'close-x' : 'menu'}.svg`} alt="menu" onClick={handleMenu}/>
        </div>
      </div>
      <div className={`body ${menuActive ? 'active' : ''}`}>
        <div className="slug">
          <h3>About</h3>
          <span>This is the development studio of</span>
          {/* <span>development studio of</span> */}
          <span>Francisco Diaz,</span>
          <span>a seasoned web developer</span>
          <span>living in Vancouver, Canada.</span>
        </div>
        <div className="social">
          <a
            className="icon "
            href="https://www.linkedin.com/in/jfranciscodiaz/"
          >
            <img
              className="linkedin"
              src="./assets/linkedin.svg"
              alt="linkedin icon"
            />
          </a>
          <a className="icon " href="https://github.com/picssel">
            <img
              className="github"
              src="./assets/github.svg"
              alt="github icon"
            />
          </a>
          <a
            className="icon "
            href="https://stackoverflow.com/users/1055987/jfk"
          >
            <img
              className="stackoverflow"
              src="./assets/stackoverflow.svg"
              alt="stackoverflow icon"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
